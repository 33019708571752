import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import store, { setIsConnected, setUserName } from "../redux/maxStore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignIn() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#f48fb1",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
              background-color: #212121;
              color: #fff; /* Text color */
          }
          `,
      },
    },
  });

  const navigate = useNavigate();
  const [user, setUser] = useState<{ error: any; user: any }>({
    user: null,
    error: null,
  });
  const logIn = async (event: any) => {
    try {
      const data = new FormData(event.currentTarget);
      const headers = {
        "Content-Type": "application/json",
      };
      const username: string = (data.get("username") as string) || "";
      const body = {
        user: {
          email: data.get("email"),
          password: data.get("password"),
          username,
        },

        rememberMe: data.get("remember"),
      };
      setUser((prev) => {
        return {
          ...prev,
          user: { username, email: body.user.email },
        };
      });
      const isGuestSubmit = event.nativeEvent?.submitter?.id === "guestButton";
      if (isGuestSubmit) {
        localStorage.setItem("token", "guest");
        navigate("/chat");
      } else {
        const response = await fetch("http://localhost:8081/auth/login", {
          method: "POST",
          body: JSON.stringify(body),
          headers: headers,
        });
        console.log(response);
        if (response.ok) {
          toast.success("Login successful!");
          const data = await response.json();
          const token = data.token;
          localStorage.setItem("token", token);
          store.dispatch(setIsConnected(true));
          navigate("/chat");
        } else {
          toast.error("Invalid username or password");
        }
      }
    } catch (error) {
      console.error(error);
      user.error = error;
      toast.error("An error occurred during login");
    }
  };
  const [remember, setRemember] = React.useState(true);
  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      await logIn(event);
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    document.title = "daniel wende";
    if (user.user) {
      store.dispatch(setUserName(user.user.username));
    } else {
      store.dispatch(setUserName(""));
    }
  }, [user]);

  return (
    <>
      <ToastContainer theme="dark" />
      <ThemeProvider theme={darkTheme}>
        <Container
          component="main"
          maxWidth="xs"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "150px auto auto auto",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                select={false}
                autoComplete="email"
                autoFocus
                sx={{
                  "&:focus": {
                    outline: "none",
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                select={false}
                autoComplete="username"
                sx={{
                  "&:focus": {
                    outline: "none",
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                autoComplete="current-password"
                type="password"
                id="password"
                select={false}
                sx={{
                  "&:focus": {
                    outline: "none",
                    backgroundColor: "#424242",
                    color: "#fff",
                  },
                }}
              />
              <FormControlLabel
                defaultChecked
                control={
                  <Checkbox
                    defaultChecked
                    value={remember}
                    onChange={(e) => {
                      setRemember(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                name="remember"
                id="remember"
                label="Remember me"
              />
              <div className="super-button-2">
                <button type="submit" className="button">
                  Sign In
                </button>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="super-button">
                    <button id="guestButton" className="button type1"></button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default SignIn;
