import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";

interface IProps {
  list: any[];
}

const Grid = (props: IProps) => {
  const [boxes, setBoxes] = useState<
    { id: number; x: number; y: number; item: React.ReactElement }[]
  >([]);

  useEffect(() => {
    const listOxfBoxes = props?.list?.map((_, index: number) => {
      const storedPosition = JSON.parse(
        localStorage.getItem(`box_${index}`) || "{}"
      );
      return {
        id: index,
        x: storedPosition.x || 0,
        y: storedPosition.y || 0,
        item: _,
      };
    });
    setBoxes(listOxfBoxes);
  }, [props.list]);

  const handleDrag = (index: any, newX: any, newY: any) => {
    setBoxes((prevBoxes) => {
      const newBoxes = [...prevBoxes];
      newBoxes[index] = { ...newBoxes[index], x: newX, y: newY };
      return newBoxes;
    });
    localStorage.setItem(`box_${index}`, JSON.stringify({ x: newX, y: newY }));
  };

  return (
    <div className="grid">
      {boxes.map((box, index) => (
        <Draggable
          key={box.id}
          defaultPosition={{ x: box.x, y: box.y }}
          onStop={(e, { x, y }) => {
            handleDrag(index, x, y);
          }}
          handle=".handle"
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div style={{ width: "100%", height: "100%" }}>{box.item}</div>
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default Grid;
