import moment from "moment";
import { useEffect, useState, useRef } from "react";
import store from "../redux/maxStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Chat = (props: { sendMessage: Function }) => {
  const [message, setMessage] = useState("");
  const [channel, setChannel] = useState("main");
  const [tasks, setTasks] = useState([]);

  const navigate = useNavigate();

  const send = () => {
    if (message.length === 0) return;
    switch (message.toLowerCase()) {
      case "tasks":
        navigate("/tasks");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "passwords":
        navigate("/managePasswords");
        break;
      case "projects":
        navigate("/projects");
        break;
      case "account":
        navigate("/account");
        break;
      default:
        props.sendMessage(
          `${channel}:${store.getState().username}: ${message}`
        );
        break;
    }
    setMessage("");
  };

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState();
      setChannel(state.channel || channel);
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      send();
    }
  };

  const scrollableDivRef: any = useRef(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight + 10;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch tasks from the server when the component mounts
  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get("http://localhost:8081/tasks/tasks");
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  return (
    <div ref={scrollableDivRef} className="chat-wrapper">
      <div className="display-chat">
        <MessageDisplay />
      </div>
      <div className="input-wrapper">
        <input
          className="message-input"
          type="text"
          autoFocus
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button className="send-button" onClick={send}>
          Send
        </button>
      </div>
    </div>
  );
};

function CopyButton({ text }: any) {
  const copyToClipboard = () => {
    console.log("text", text);
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  return (
    <div className="copy-container">
      <button className="copy-button" onClick={copyToClipboard}>
        Copy
      </button>
    </div>
  );
}

function MessageComponent({ message }: { message: any }) {
  const formattedContent = message.content.replace(/\r/g, "<br/>");
  const textRef: any = useRef(null);
  return (
    <>
      <span
        ref={textRef}
        className="message"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      ></span>
      <CopyButton ref={textRef} text={formattedContent} />
      <span className="timestamp">
        {moment().diff(moment(message.timestamp), "minutes")}
      </span>
    </>
  );
}

const MessageDisplay = () => {
  const [messageLog, setMessageLog] = useState<any>([]);
  store.subscribe(() => {
    const state = store.getState();
    setMessageLog(state.value);
  });

  return (
    <div className="message-display">
      <div className="message-list">
        {messageLog.map((message: any, index: number) => (
          <div key={index}>
            <div className="message-item">
              <span>{message.sender.name}</span>
              <MessageComponent message={message} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chat;
