import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const nav = useNavigate();
  useHotkeys("p+a", () => {
    nav("/chat");
  });
  return <></>;
};

export default Main;
