import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "./Modal"; // Assuming you have a Modal component
import "./PasswordManagerPage.scss";

interface Account {
  account: string;
  password: string;
}

interface Service {
  serviceName: string;
}

const PasswordManagerPage: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [showPasswords, setShowPasswords] = useState<boolean[]>([]);
  const [editPassword, setEditPassword] = useState<string>("");
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [accountObject, setAccountObject] = useState<any>(null);
  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<string>("");
  const [newServiceName, setNewServiceName] = useState<string>("");

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    let passwords: any = [];
    if (
      selectedService !== "" &&
      (!accountObject || !accountObject?.[selectedService])
    ) {
      fetchPasswords(selectedService);
    } else if (accountObject) {
      passwords = accountObject[selectedService];
      setAccounts([...(passwords || [])]);
    }
  }, [selectedService, accountObject]);

  const fetchServices = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response: any = await axios.get<Service[]>(
        "http://localhost:8081/managePasswords/services",
        { headers }
      );
      setServices(response.data.serviceNames || []);
      if (response.data?.serviceNames.length > 0) {
        setSelectedService(response.data.serviceNames[0].serviceName);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchPasswords = async (service: string) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response: any = await axios.post<Account[]>(
        "http://localhost:8081/managePasswords/passwords/all",
        { service },
        { headers }
      );
      setAccountObject(response.data.accounts);
      setShowPasswords(Array(response.data.length).fill(false));
    } catch (error) {
      console.error("Error fetching passwords:", error);
    }
  };

  const handleTogglePasswordVisibility = (index: number) => {
    const newShowPasswords = [...showPasswords];
    newShowPasswords[index] = !newShowPasswords[index];
    setShowPasswords(newShowPasswords);
  };

  const handleCopyToClipboard = (password: string) => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        toast("Password copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying password to clipboard:", error);
      });
  };

  const handleEditPassword = (account: Account) => {
    setSelectedAccount(account);
    // Open a modal or form for editing the password
  };

  const handleSavePassword = () => {
    const newAccounts = accounts.map((account) => {
      if (account.account === selectedAccount?.account) {
        return { account: account.account, password: editPassword };
      }
      return account;
    });

    setEditPassword("");
    setSelectedAccount(null);
  };

  const handleAddEntry = async () => {
    try {
      if (newServiceName === "") return toast("Please enter a service name");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      await axios.post(
        "http://localhost:8081/managePasswords/passwords",
        {
          service: newServiceName,
        },
        { headers }
      );
      setNewServiceName("");
      fetchPasswords(newServiceName);
      fetchServices();
    } catch (error) {
      console.error("Error adding new service:", error);
    }
  };
  const handleDeleteEntry = async (props: any) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      await axios.post(
        "http://localhost:8081/managePasswords/passwords/delete",
        {
          service: props.service,
          account: props.account,
        },
        { headers }
      );
      setNewServiceName("");
      fetchServices();
      fetchPasswords(newServiceName);
    } catch (error) {
      console.error("Error adding new service:", error);
    }
  };

  return (
    <div className="password-manager-container">
      <div className="container">
        <p>Personal Passwords</p>
        <input
          style={{ padding: "10px", borderRadius: "5px" }}
          type="text"
          value={newServiceName}
          onChange={(e) => setNewServiceName(e.target.value)}
          placeholder="Enter new service name"
        />
        <button onClick={handleAddEntry}>Add Service</button>
        <select
          value={selectedService}
          onChange={(e) => setSelectedService(e.target.value)}
        >
          {services.map((service: Service, index: number) => (
            <option key={index} value={service.serviceName}>
              {service.serviceName}
            </option>
          ))}
        </select>
      </div>
      <table className="passwords-table">
        <thead>
          <tr>
            <th>Account</th>
            <th style={{ minWidth: "280px" }}>Password</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account: Account, index: number) => (
            <tr key={index}>
              <td>{account.account}</td>
              <td>
                {showPasswords[index] ? (
                  account.password
                ) : (
                  <button onClick={() => handleTogglePasswordVisibility(index)}>
                    Show Password
                  </button>
                )}
              </td>
              <td className="buttons">
                <button onClick={() => handleCopyToClipboard(account.password)}>
                  Copy
                </button>
                <button onClick={() => handleEditPassword(account)}>
                  Edit
                </button>{" "}
                <button
                  onClick={() =>
                    handleDeleteEntry({
                      account: account.account,
                      service: selectedService,
                    })
                  }
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <GenNewPassword
        currentService={selectedService}
        fetchPasswords={fetchPasswords}
      />
      <Modal show={selectedAccount} onClose={() => setSelectedAccount(null)}>
        <p>Edit Password :</p>
        <input
          type="password"
          value={editPassword}
          style={{
            background: "transparent",
            padding: "10px",
            borderRadius: "10px",
          }}
          onChange={(e) => setEditPassword(e.target.value)}
          placeholder="Enter new password"
        />
        <button
          style={{ background: "rebeccapurple", marginLeft: "20px" }}
          onClick={handleSavePassword}
        >
          Save
        </button>
      </Modal>
    </div>
  );
};

const GenNewPassword = (props: {
  currentService: string;
  fetchPasswords: Function;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newServiceName, setNewServiceName] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");

  const handleGeneratePassword = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response: any = await axios.post(
        "http://localhost:8081/managePasswords/passwords/generate",
        {
          service: props.currentService,
          account: newServiceName,
        },
        { headers }
      );
      console.log("🚀 ~ handleGeneratePassword ~ response:", response);
      if (response.data.status === 200) {
        toast(response.data.message);
        setNewServiceName("");
        props.fetchPasswords(props.currentService);
      }
      setGeneratedPassword(response.message);
    } catch (error) {
      console.error("Error generating password:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewServiceName("");
  };

  const handleModalSubmit = () => {
    handleGeneratePassword();
    setShowModal(false);
  };

  return (
    <div>
      <button onClick={() => setShowModal(true)}>Generate New Password</button>
      <Modal show={showModal} onClose={handleModalClose}>
        <p>Please Enter Account Name:</p>
        <div className="thing">
          <input
            type="text"
            autoFocus
            onSubmit={handleModalSubmit}
            value={newServiceName}
            onChange={(e) => setNewServiceName(e.target.value)}
          />
          <button onClick={handleModalSubmit}>Generate Password</button>
        </div>
      </Modal>
    </div>
  );
};

export default PasswordManagerPage;
