import { FormControlLabel, Switch } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import "./settings.scss"; // Import CSS file for styling

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const SettingsPage = () => {
  const [tokenExpirationTime, setTokenExpirationTime] = useState("");
  const [allowGuests, setAllowGuests] = useState(false);
  const [paCommands, setPACommands] = useState(false);
  const [alwaysShowPAOutput, setAlwaysShowPAOutput] = useState(false);
  const [selectedOption, setSelectedOption] = useState("allowGuests");

  const handleTokenExpirationChange = (e: any) => {
    const value = e.target.value;
    setTokenExpirationTime(value);
    // Add validation if needed
  };

  return (
    <div className="settings-container">
      <h2 className="main-title">Settings</h2>
      <hr />
      <h3 className="sub-title">site</h3>
      <div className="setting-item">
        <label>Token Expiration Time:</label>
        <input
          type="text"
          value={tokenExpirationTime}
          onChange={handleTokenExpirationChange}
          placeholder="Enter Token Expiration Time"
        />
      </div>
      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={<Android12Switch defaultChecked />}
          label="Allow Guests to the site:"
        />
      </div>
      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={<Android12Switch defaultChecked />}
          label="Personal Assistant Cli Commands :"
        />
      </div>
      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={<Android12Switch defaultChecked />}
          label="Personal Assistant Cli output:"
        />
      </div>
      <div>
        <h3 className="sub-title">Archived Chats</h3>
        <button>Archive All Chats</button>
        <button>Delete All Chats</button>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <h3 className="sub-title">Manage Archive</h3>
        <button>Archive All</button>
        <button>Delete All</button>
      </div>
      <hr />
      <h3 className="sub-title">account </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          gap: "10px",
        }}
      >
        <h3 className="sub-title">Users</h3>
        <button>Create new user </button>
        <button>Delete All Users</button>
        <button>Delete This User</button>
      </div>
    </div>
  );
};

export default SettingsPage;
