import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";

const Page: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:8081/repos");
        setData(response.data);
        setLoading(false);
      } catch (error: any) {
        console.log("🚀 ~ fetchData ~ error:", error);
        setError(error.response.data.message || "An error occurred");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="github-repos-container">
      {loading && (
        <div className="loading">
          <p>Loading...</p>
        </div>
      )}
      {error && <p className="error">Error: {error}</p>}
      {data && (
        <div className="repo-list">
          {data.map((repo: any) => (
            <div className="repo-card" key={repo.id}>
              <h3 className="repo-title">{repo.name}</h3>
              <p className="repo-description">{repo.description}</p>
              <p className="repo-language">Language: {repo.language}</p>
              <p className="repo-created-at">
                Created At: {new Date(repo.created_at).toLocaleDateString()}
              </p>
              <div className="buttons-container">
                <Button
                  href={repo.html_url}
                  className="button live-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Preview
                </Button>
                <Button
                  href={`${repo.html_url}/tree/master`}
                  className="button source-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source Code
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Page;
