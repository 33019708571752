// Account.tsx
import React from "react";

const Account: React.FC = () => {
  return (
    <div className="page-container">
      <h2>Account</h2>
      <p>This is the Account page.</p>
    </div>
  );

};

export default Account;
