import {
  faCog,
  faProjectDiagram,
  faSignOutAlt,
  faTasks,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import animeBoy from "../svg/animeboy.svg";
import store, { setUserName } from "../redux/maxStore";
const drawerWidth = 240;
const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#171717",
  },
  toolbar: theme.mixins.toolbar,
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  profileIcon: {
    fontSize: "2rem",
    marginBottom: theme.spacing(1),
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    // alert("asd");
    store.dispatch(setUserName(""));
    navigate("/login");
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List>
            <ListItem button component={Link} to="/chat">
              <div className={classes.profile}>
                <img src={animeBoy} alt="profile" width={"100%"} />
                <Typography variant="subtitle1" align="center">
                  {localStorage.getItem("token") === "guest"
                    ? "daniel wendemeneh"
                    : "Personal Assistant"}
                </Typography>
              </div>
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/tasks">
              <ListItemIcon>
                <FontAwesomeIcon icon={faTasks} />
              </ListItemIcon>
              <ListItemText primary="Tasks" />
            </ListItem>
            <ListItem button component={Link} to="/projects">
              <ListItemIcon>
                <FontAwesomeIcon icon={faProjectDiagram} />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
            <ListItem button component={Link} to="/managePasswords">
              <ListItemIcon>
                <FontAwesomeIcon icon={faUnlockAlt} />
              </ListItemIcon>
              <ListItemText primary="Passwords" />
            </ListItem>
            <ListItem button component={Link} to="/account">
              <ListItemIcon>
                <FontAwesomeIcon icon={faUser} />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
            <ListItem button component={Link} to="/settings">
              <ListItemIcon>
                <FontAwesomeIcon icon={faCog} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
      </ThemeProvider>
    </div>
  );
};

export default Sidebar;
