import axios from "axios";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { useHotkeys } from "react-hotkeys-hook";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import WebSocketClient from "./sockets/socket";
import Sidebar from "./components/Sidebar"; // Import the Sidebar component
import {
  Account,
  Chat,
  Main,
  Passwords,
  Projects,
  Settings,
  SignIn,
  Tasks,
} from "./components/index";
import store from "./redux/maxStore";
const SidebarWrapper = ({ isAuthenticated }: any) => {
  return (
    <div
      style={{
        position: "sticky",
        display: "flex",
        background: "#171717",
        left: "0",
        top: "0",
      }}
    >
      {isAuthenticated && <Sidebar />}
    </div>
  );
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const MainWrapper = () => {
  const { sendMessage } = WebSocketClient();
  const [open, setOpen] = useState(false);

  axios.defaults.baseURL = "http://localhost:8081";
  store.subscribe(() => {
    const state = store.getState();
    setOpen(state.isConnected);
  });
  useEffect(() => {
    const isConnected = store.getState().isConnected;
    setOpen(isConnected);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <>
        <BrowserRouter>
          <ToastContainer theme="dark" />
          <div
            style={{
              position: "sticky",
              display: "flex",
              background: "#171717",
              left: "0",
              top: "0",
            }}
          >
            <SidebarWrapper isAuthenticated={open} />
          </div>
          <Main />
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/chat" element={<Chat sendMessage={sendMessage} />} />
            <Route
              path="/"
              element={
                open ? <Navigate to="/chat" /> : <Navigate to="/login" />
              }
            />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/managePasswords" element={<Passwords />} />
            <Route path="/account" element={<Account />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
};

root.render(
  <Provider store={store}>
    <MainWrapper />
  </Provider>
);
