import { DragIndicator } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Grid from "./Grid";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
export enum tabOptions {
  TASKS = "tasks",
  NOTES = "notes",
  REMINDERS = "reminders",
}

export interface Item {
  id: number;
  title: string;
  type: string;
  content?: string;
  completed?: boolean;
  dateTime?: string;
}

export interface ItemProps {
  item: Item;
  type: tabOptions;
  onUpdateItem: () => void;
}

export interface ReminderProps {
  dueTime: Date;
}

const Reminder: React.FC<ReminderProps> = ({ dueTime }) => {
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = dueTime.getTime() - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      console.log(
        "🚀 ~ interval ~ `${days}d ${hours}h ${minutes}m ${seconds}s`:",
        `${days}d ${hours}h ${minutes}m ${seconds}s`
      );
      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft("Expired");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dueTime]);

  return <div>{timeLeft}</div>;
};

const ItemComponentOriginal: React.FC<ItemProps> = ({
  item,
  type,
  onUpdateItem,
}) => {
  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState<string>(item.title);
  const [editedContent, setEditedContent] = useState<string>(
    item?.content || ""
  );

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(e.target.value);
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedContent(e.target.value);
  };

  const handleDoubleClick = () => {
    setEditing(true);
    if ("title" in item) setEditedTitle(item.title);
    if ("content" in item) setEditedContent(item?.content || "");
  };

  const handleSubmit = async () => {
    setEditing(false);
    try {
      await axios.post("tasks/item", {
        ...item,
        title: editedTitle,
        content: editedContent,
      });
    } catch (error) {
      console.error(error);
    }
    onUpdateItem();
  };
  const date = new Date(2024, 6, 10, 12, 0, 0);
  useHotkeys("Enter", handleSubmit);
  return (
    <div className={"card"}>
      {editing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <input
            type="text"
            autoFocus
            style={{
              margin: "20px 0 5px 0",
              width: "100%",
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
              borderBottom: "1px solid white",
              color: "white",
            }}
            value={editedTitle}
            onChange={handleTitleChange}
          />
          <textarea
            style={{
              margin: "20px 0 5px 0",
              width: "fit-content",
              minWidth: "300px",
              minHeight: "125px",
              backgroundColor: "transparent",
              border: "none",
              borderBottom: "1px solid white",
              color: "white",
            }}
            value={editedContent}
            onChange={handleContentChange}
          />
          <button
            style={{
              background: "transparent",
              border: "solid 1px white",
              borderRadius: "2.5px",
              cursor: "pointer",
              padding: "5px 10px",
              textAlign: "center",
              color: "white",
            }}
            onClick={handleSubmit}
          >
            submit
          </button>
        </div>
      ) : (
        <div
          style={{ width: "100%", minWidth: "300px" }}
          onDoubleClick={handleDoubleClick}
        >
          {type === tabOptions.NOTES ? (
            <div className="handle" style={{ cursor: "move", display: "flex" }}>
              <DragIndicator />
              <h3>{"title" in item ? item.title : ""}</h3>
            </div>
          ) : (
            <h3> {"title" in item ? item.title : ""}</h3>
          )}

          {type === tabOptions.REMINDERS && <Reminder dueTime={date} />}
          <p style={{ whiteSpace: "pre-wrap" }}>{item.content ?? ""}</p>
        </div>
      )}
    </div>
  );
};

interface NotesComponentProps {
  item: Item;
}

const NotesComponent: React.FC<NotesComponentProps> = ({ item }) => (
  <div className="handle" style={{ cursor: "move", display: "flex" }}>
    <div style={{ display: "flex", gap: "10px" }}>
      <DragIndicator />
      <h3>{item.title}</h3>
    </div>
    <p className="item-content">{item.content ?? ""}</p>
  </div>
);
interface TasksComponentProps {
  item: Item;
}

const TasksComponent: React.FC<TasksComponentProps> = ({ item }) => (
  <>
    <h3>{item.title}</h3>
    <p className="item-content">{item.content ?? ""}</p>
    {item.completed ? <p>Completed</p> : <p>Not Completed</p>}
  </>
);

interface RemindersComponentProps {
  item: Item;
  dueTime: Date;
}

const RemindersComponent: React.FC<RemindersComponentProps> = ({
  item,
  dueTime,
}) => (
  <div>
    <h3>{item.title}</h3>
    <BasicDateTimePicker />
    <Reminder dueTime={dueTime} />
    <p className="item-content">{item.content ?? ""}</p>
  </div>
);

function BasicDateTimePicker() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DateTimePicker label="Due Time" />
      </DemoContainer>
    </LocalizationProvider>
  );
}
const ItemComponent: React.FC<ItemProps> = ({ item, type, onUpdateItem }) => {
  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState<string>(item.title);
  const [editedContent, setEditedContent] = useState<string>(
    item?.content || ""
  );

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(e.target.value);
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedContent(e.target.value);
  };

  const handleDoubleClick = () => {
    setEditing(true);
    setEditedTitle(item.title);
    setEditedContent(item?.content || "");
  };

  const handleSubmit = async () => {
    setEditing(false);
    try {
      await axios.post("tasks/item", {
        ...item,
        title: editedTitle,
        content: editedContent,
      });
    } catch (error) {
      console.error(error);
    }
    onUpdateItem();
  };

  const date = new Date(2024, 6, 10, 12, 0, 0);

  useHotkeys("Enter", handleSubmit);

  const renderContent = () => {
    switch (type) {
      case tabOptions.NOTES:
        return <NotesComponent item={item} />;
      case tabOptions.REMINDERS:
        return <RemindersComponent item={item} dueTime={date} />;
      case tabOptions.TASKS:
        return <TasksComponent item={item} />;
      default:
        return null;
    }
  };

  return (
    <div className="card">
      {editing ? (
        <div className="edit-container">
          <input
            type="text"
            autoFocus
            className="edit-input"
            value={editedTitle}
            onChange={handleTitleChange}
          />
          <textarea
            className="edit-textarea"
            value={editedContent}
            onChange={handleContentChange}
          />
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      ) : (
        <div className="item-view" onDoubleClick={handleDoubleClick}>
          {renderContent()}
        </div>
      )}
    </div>
  );
};
const Tasks: React.FC = () => {
  const [tasks, setTasks] = useState<Item[]>([]);
  const [notes, setNotes] = useState<Item[]>([]);
  const [reminders, setReminders] = useState<Item[]>([]);
  const [selected, setSelected] = useState<tabOptions>(tabOptions.TASKS);

  useHotkeys("t", () => {
    setSelected(tabOptions.TASKS);
  });

  const RenderList = () => {
    switch (selected) {
      case tabOptions.TASKS:
        return (
          <>
            {tasks.map((task, index) => (
              <ItemComponent
                key={index}
                item={task}
                type={tabOptions.TASKS}
                onUpdateItem={fetchItems}
              />
            ))}
          </>
        );
      case tabOptions.NOTES:
        return (
          <div>
            <Grid
              list={notes.map((note, index) => (
                <ItemComponent
                  key={index}
                  item={note}
                  type={tabOptions.NOTES}
                  onUpdateItem={fetchItems}
                />
              ))}
            />
          </div>
        );
      case tabOptions.REMINDERS:
        return (
          <>
            {reminders.map((reminder, index) => (
              <ItemComponent
                key={index}
                item={reminder}
                type={tabOptions.REMINDERS}
                onUpdateItem={fetchItems}
              />
            ))}
          </>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const tasksResponse = await axios.get("tasks/tasks");
      setTasks(tasksResponse.data);

      const notesResponse = await axios.get("tasks/notes");
      setNotes(notesResponse.data);

      const remindersResponse = await axios.get("tasks/reminders");
      setReminders(remindersResponse.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const handleCreation = async () => {
    let newItem: Item;
    switch (selected) {
      case tabOptions.NOTES:
        newItem = {
          id: notes.length,
          title: "New Note",
          content: "Write Note Here",
          type: "note",
        };
        await axios.post("tasks/tasks", newItem);
        setNotes((prev) => [...prev, newItem]);
        break;
      case tabOptions.TASKS:
        newItem = {
          id: tasks.length,
          title: "New Task",
          content: "oh shit here we go again",
          completed: false,
          type: "task",
        };
        await axios.post("tasks/tasks", newItem);
        setTasks((prev) => [...prev, newItem]);
        break;
      case tabOptions.REMINDERS:
        newItem = {
          id: reminders.length,
          title: "New Reminder",
          content: "oh shit here we go again",
          dateTime: "2024-03-30T09:00:00",
          type: "reminder",
        };
        await axios.post("tasks/tasks", newItem);
        setReminders((prev) => [...prev, newItem]);
        break;
    }
  };

  useHotkeys("c", handleCreation);
  useHotkeys("r", () => setSelected(tabOptions.REMINDERS));
  useHotkeys("n", () => setSelected(tabOptions.NOTES));

  return (
    <div className="page-container">
      <div className="options">
        <p
          onClick={() => setSelected(tabOptions.TASKS)}
          className={`${selected === tabOptions.TASKS ? "selected" : ""}`}
        >
          <span>Tasks</span>
        </p>
        <p
          onClick={() => setSelected(tabOptions.NOTES)}
          className={`${selected === tabOptions.NOTES ? "selected" : ""}`}
        >
          <span>Notes</span>
        </p>
        <p
          onClick={() => setSelected(tabOptions.REMINDERS)}
          className={`${selected === tabOptions.REMINDERS ? "selected" : ""}`}
        >
          <span>Reminders</span>
        </p>
      </div>
      <div className="list-container">
        <RenderList />
      </div>
    </div>
  );
};

export default Tasks;
